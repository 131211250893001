import { handleActions } from "redux-actions";
const defaultState = {
  __persist: false,
  reportData: {
    permits: [],
    completions: [],
    // applications: [],
    regulatory: [],
    welltransfers: [],
    spudnotice: [],
  },
  pdf: null,
  error: null,
  loading: false,
};

export default handleActions(
  {
    FETCH_DAILY_REPORT_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },

    FETCH_DAILY_REPORT_SUCCESS: (state, { payload: data }) => {
      const reportData = defaultState.reportData;
      let id = 0;
      Object.keys(reportData).forEach((key) => {
        if (data[key]?.length) {
          const repData = data[key].map((i) => {
            id++;
            return {
              ...i,
              rowId: id,
              ad: i.ad ? new Date(i.ad) : null,
              ed: i.ed ? new Date(i.ed) : null,
              pd: i.pd ? new Date(i.pd) : null,
              sd: i.sd ? new Date(i.sd) : null,
              cd: i.cd ? new Date(i.cd) : null,
              fd: i.fd ? new Date(i.fd) : null,
              md: i.md ? new Date(i.md) : null,
              dr: i.dr ? new Date(i.dr) : null,
              td: i.td ? new Date(i.td) : null,
              tda: i.tda ? new Date(i.tda) : null,
              expand: false,
            };
          });
          reportData[key] = repData;
        } else {
          reportData[key] = [];
        }
      });
      return {
        ...state,
        reportData,
        loading: false,
      };
    },

    FETCH_DAILY_REPORT_FAILURE: (state, { payload: err }) => {
      return {
        ...state,
        reportData: defaultState.reportData,
        loading: false,
        error: err,
      };
    },

    SET_REPORT_ROW_HEIGHT_AND_MODE: (
      state,
      { payload: { id, tab, height, isExpand } }
    ) => {
      const udpatedReport = state.reportData;
      const data = udpatedReport[tab].map((row) => {
        if (row.rowId === id) {
          row.height = height;
          row.expand = isExpand;
        }
        return row;
      });
      udpatedReport[tab] = data;
      return {
        ...state,
        reportData: { ...udpatedReport },
      };
    },

    FETCH_DAILY_REPORT_DOC_REQUEST: (state) => {
      return {
        ...state,
        pdf: null,
      };
    },

    FETCH_DAILY_REPORT_DOC_SUCCESS: (state, { payload: { pdfData } }) => {
      const iPDF = window.URL.createObjectURL(
          new Blob([pdfData], { type: "application/pdf" })
      );
      
      // // Create a temporary <a> element to trigger the download
      // const a = document.createElement("a");
      // a.href = iPDF;
      // a.download = "report.pdf"; // Specify the file name for the download
      
      // // Append the <a> to the document and trigger the click event
      // document.body.appendChild(a);
      // a.click();

      // // Clean up and remove the temporary anchor
      // document.body.removeChild(a);

      // // Revoke the object URL to release memory
      // // window.URL.revokeObjectURL(iPDF);
      return {
        ...state,
        pdf: iPDF,
        error: null,
      };
    },

    FETCH_DAILY_REPORT_DOC_FAILURE: (state, { payload: { err } }) => {
      return {
        ...state,
        pdf: null,
        error: err,
      };
    },
  },
  defaultState
);

import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const MyVerticalBarChart = ({ data }) => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const aggregateEvents = (data) => {
      const userEventCounts = {};

      data.forEach(event => {
        const user = event.UserName;
        const eventType = event.EventType;

        if (!userEventCounts[user]) {
          userEventCounts[user] = { name: user, Login: 0, Logout: 0, Search: 0, OKCRIntegration: 0 };
        }

        if (eventType === 'Login') {
          userEventCounts[user].Login += 1;
        } else if (eventType === 'Logout') {
          userEventCounts[user].Logout += 1;
        } else if (eventType === 'Search') {
          userEventCounts[user].Search += 1;
        } else if (eventType === 'OKCR Integration') {
          userEventCounts[user].OKCRIntegration += 1;
        }
      });

      return Object.values(userEventCounts);
    };

    const aggregatedData = aggregateEvents(data);
    setUserData(aggregatedData);
  }, [data]);

  const chartHeight = userData.length * 80 + 100;
  return (
    <div style={{ height: '500px', overflowY: 'auto', position: 'relative' }}>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <BarChart 
          data={userData} 
          layout="vertical"
          margin={{ top: 40, right: 50, left: 50, bottom: 50 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis 
            type="category" 
            dataKey="name" 
            tick={{ fontSize: 12 }} 
            width={200} 
          />
          <XAxis type="number" tick={{ fontSize: 12 }} />
          <Tooltip />
          <Legend layout="horizontal" align="center" verticalAlign="top" wrapperStyle={{ top: 10 }} />
          
          <Bar dataKey="Login" fill="#8884d8" name="Login" barSize={60}>
            <LabelList
              dataKey="Login"
              position="inside"
              fill="#fff"
              fontSize="10px"
              formatter={(value) => {
                return value >= 30 ? value : '';
              }}
            />
          </Bar>
          <Bar dataKey="Logout" fill="#82ca9d" name="Logout" barSize={60}>
            <LabelList
              dataKey="Logout"
              position="inside"
              fill="#fff"
              fontSize="10px"
              formatter={(value) => {
                return value >= 30 ? value : '';
              }}
            />
          </Bar>
          <Bar dataKey="Search" fill="#ffc658" name="Search" barSize={60}>
            <LabelList
              dataKey="Search"
              position="inside"
              fill="#fff"
              fontSize="10px"
              formatter={(value) => {
                return value >= 30 ? value : '';
              }}
            />
          </Bar>
          <Bar dataKey="OKCRIntegration" fill="#ff7300" name="OKCR Integration" barSize={60}>
            <LabelList
              dataKey="OKCRIntegration"
              position="inside"
              fill="#fff"
              fontSize="10px"
              formatter={(value) => {
                return value >= 30 ? value : '';
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MyVerticalBarChart;
